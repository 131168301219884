import { useQuery } from "react-query";
import { campaignClient } from "../../api";
import { PageLoader } from "../../Components";
import { useMemo } from "react";
import { CSVLink } from "react-csv";

function Overview() {
  const { isLoading: isLoadingDownloads, data: download } = useQuery("downloads", async () => {
    const res: [] = await campaignClient.call("downloads", {});
    return res;
  });

  const { isLoading: isLoadingLearnMore, data: learnMore } = useQuery("learn", async () => {
    const res: [] = await campaignClient.call("learn", {});
    return res;
  });

  const { isLoading: isLoadingGames, data: games } = useQuery("games", async () => {
    const res: [] = await campaignClient.call("games", {});
    return res;
  });

  const stats = useMemo(() => {
    if (learnMore && games && download) {
      return {
        downloads: download?.length,
        learn: learnMore?.length,
        game: games?.length,
      };
    }
  }, [learnMore, games, download]);

  const data = useMemo(() => {
    return [
      { "Event Name": "Hide Guide Downloads", "Total Clicks": stats?.downloads },
      { "Event Name": "Learn More clicks on charity page", "Total Clicks": stats?.learn },
      { "Event Name": "Play Hide and Find clicks on landing page", "Total Clicks": stats?.game },
    ];
  }, [stats]);

  if (isLoadingDownloads || isLoadingGames || isLoadingLearnMore) {
    return <PageLoader />;
  }

  return !isLoadingDownloads && !isLoadingGames && !isLoadingLearnMore ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Overview</h2>

        <div className="main__actions">
          <li>
            <CSVLink
              data={data ? data : ""}
              filename={`Campaign-Report-${new Date().toLocaleDateString()}`}
              asyncOnClick={true}
              target="_blank"
              className="btn btn--medium btn--mobile-small">
              Download Report
            </CSVLink>
          </li>
        </div>
      </div>
      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="boxes-info">
          <ul>
            <li>
              <div className="info-box">
                <p>Hide Guide Downloads</p>
                <h1>{stats?.downloads}</h1>
                <p>Learn More</p>
                <h1>{stats?.learn}</h1>
                <p>Play Hide and Find</p>
                <h1>{stats?.game}</h1>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any stats to display.</p>
  );
}

export default Overview;
